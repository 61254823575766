import React from 'react'
import Layout from '../components/layout';
import {graphql} from 'gatsby'
import Img from "gatsby-image";

import FeaturesShapes from "../blocks/features-shapes";
import FeaturesOutlines from "../blocks/features-outlines";
import FeaturesFills from "../blocks/features-fills";
import DownloadButton from "../components/download-button";
import HeroBackground from "../components/hero-background";

export default class IndexPage extends React.Component {

    render()
    {
        return (
            <Layout lang={'en'}
                    title={'2stitch Studio - affordable embroidery software'}
                    description={'Affordable embroidery software for MacOS and Windows. Supports all common machine  brands such as Bernina, Brother, Husqvarna, Pfaff, Janome'}
                    translated={{de:'/de/studio/'}}
                    location={this.props.location.pathname}
            >

                <div className={'leading-normal text-lg'}>
                    <HeroBackground img={this.props.data.heroImage.childImageSharp.fluid}>
                        <div className="container mx-auto text-center">
                            <h1 className="text-xl md:text-5xl text-blue-50 font-medium mb-3 ">2stitch Studio</h1>
                            <p className="text-md md:text-3xl text-blue-50">Affordable embroidery
                                software.
                                Easy as cake.</p>

                            <DownloadButton linkMac={'/download/'} linkWindows={'/download/'}/>

                        </div>
                    </HeroBackground>


                    <div className="bg-blue-500 text-blue-50 pt-12 pb-8">
                        <div className={"container mx-auto"}>
                            <FeaturesShapes/>
                        </div>
                    </div>

                    <div className={''}>


                        <div className="container mx-auto pt-12 pb-4 ">

                            <div className="section-header">
                                <h2>So, what does it look like?</h2>
                                <p>
                                    Our focus is to provide you with a clean and easy to use piece of software.
                                </p>
                            </div>

                            <div className="container mx-auto">

                                <Img fluid={this.props.data.screenShotImage.childImageSharp.fluid}/>

                            </div>
                        </div>
                    </div>

                    <div className="bg-blue-500 text-blue-50">
                        <div className="container mx-auto pt-12 pb-6 ">
                            <div className="section-header">
                                <h2>Stitches &amp; Fills</h2>
                                <p>
                                    Use the following stitches to create your designs.<br/>

                                    More fill and outline types will be available in future versions
                                    of the software.
                                </p>
                            </div>

                            <FeaturesOutlines/>
                            <FeaturesFills/>
                        </div>
                    </div>


                    <div className="bg-blue-50 text-blue-900">

                        <div className="container mx-auto pt-12 pb-12">

                            <div className="section-header">
                                <h2>Works on your computer and your embroidery machine</h2>
                                <p>
                                    Runs on Windows, MacOS and Ubuntu (Linux).<br/>
                                    No Emulator. Instant download.<br/>
                                    Fair pricing. No dongle.<br/>
                                </p>
                            </div>

                            <div className="mt-12">
                                <h3>Supported embroidery machine brands</h3>
                            </div>

                            <p>
                                2stitch can export your designs in for following formats for the
                                common embroidery machine brands:
                            </p>

                            <div className="row mt-4">


                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Poem, Huskygram, Singer</td>
                                            <td className={'w-1/6 xl:w-auto'}>CSD</td>
                                        </tr>
                                        <tr>
                                            <td>Tajima</td>
                                            <td>DST</td>
                                        </tr>
                                        <tr>
                                            <td>ZSK</td>
                                            <td>DSZ</td>
                                        </tr>
                                        <tr>
                                            <td>Melco</td>
                                            <td>EXP</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>

                                        <tr>
                                            <td>Husqvarna/Viking</td>
                                            <td className={'w-1/6 xl:w-auto'}>HUS</td>
                                        </tr>
                                        <tr>
                                            <td>Janome/Elna/Kenmore</td>
                                            <td>JEF</td>
                                        </tr>
                                        <tr>
                                            <td>Pfaff</td>
                                            <td>PCS</td>
                                        </tr>
                                        <tr>
                                            <td>Babylock, Brother, Deco</td>
                                            <td>PEC, PES</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Janome, Elna, Kenmore</td>
                                            <td className={'w-1/6 xl:w-auto'}>SEW</td>
                                        </tr>
                                        <tr>
                                            <td>Happy</td>
                                            <td>TAP</td>
                                        </tr>
                                        <tr>
                                            <td>Husqvarna Viking, Pfaff</td>
                                            <td>VIP</td>
                                        </tr>
                                        <tr>
                                            <td>Compucon, Singer</td>
                                            <td>XXX</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-blue-500 pt-12 pb-12 text-blue-50">
                    <div className="container mx-auto">

                        <div className="section-header">
                            <h2>And finally...</h2>
                        </div>

                        <div className={'row flex-wrap leading-normal text-lg'}>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Automatic Undersewing</h4>
                                <p className=''>
                                    Fills and satin stiches require some underlay to stabilize the design.
                                    2stitch automatically creates it for you.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Pull compensation</h4>
                                <p>
                                    Pull compensation extends the fill stitches outside of the shape
                                    to compensate for pull effects in the fabric.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Thread chest</h4>
                                <p>
                                    2stitch contains a library of common thread brands and their colors to choose
                                    from.
                                    Build your own palette from the colors you have at your disposal.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Stitch simulation</h4>
                                <p>
                                    Preview how your design is stitched before sending it to your embroidery
                                    machine.
                                </p>

                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Realistic and stitch view</h4>
                                <p>
                                    See stitches in detail in the stitch view or preview your desing using
                                    the realistic render mode.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Import designs</h4>
                                <p>
                                    You can import existing embroidery designs in the following formats:<br/>

                                    .ART, .CND,
                                    .CSD, .DST, .DSZ, .EMB, .EMD,
                                    .EXP, .HUS, .JAN, .JEF,
                                    .PCS, .PCM, .PES, .PHB,
                                    .PHC, .PHD, .SEW, .SHV,
                                    .TAP, .VIP, .VP3, .XXX
                                </p>
                            </div>
                        </div>

                    </div>

                </div>


                <Img fluid={this.props.data.footerImage.childImageSharp.fluid}/>

            </Layout>
        )
            ;
    }
}


export const pageQuery = graphql`
query {

    heroImage: file(relativePath: { eq: "parallax-2.jpg" }) {
      ...fluidImage
    },

    screenShotImage: file(relativePath: { eq: "screenshot.jpg" }) {
      ...fluidImage
    },

    footerImage: file(relativePath: { eq: "threads.jpg" }) {
      ...fluidImage
    }
  }
`;
