import React from 'react'

import SatinFillIcon from '../img/SVG/statin-fill.svg';
import PatternFillIcon from '../img/SVG/pattern-fill.svg';
import MotiveFillIcon from '../img/SVG/motive-fill.svg';

import Feature from '../components/feature';


export const FeaturesFills = () => (

    <div className="text-center">
        <div className="row">
            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Satin Fill"} icon={<SatinFillIcon/>}>
                    Satin fill applies a shiny fill stitch to fill your shapes.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Motive Fill"} icon={<MotiveFillIcon/>}>
                    Fills the shape with a motive from the library.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Pattern Fill"} icon={<PatternFillIcon/>}>
                    Fills the shape with a pattern such as Tatami, Hearts, Diamonds and more.
                </Feature>
            </div>
        </div>

    </div>

);

export default FeaturesFills
