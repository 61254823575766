import React from 'react'


import SymbolLibraryImage from '../img/SVG/symbol-library.svg';
import YourOwnDesignImage from '../img/SVG/own-design.svg';
import FontImage from '../img/SVG/fonts.svg';

import Row from '../components/row.js';


class ImageBlock extends React.Component {

    render() {
        return (
        <Row className={'flex flex-wrap mt-8 mb-8 text-lg items-center'}>
            <div className="w-full lg:pr-2 mx-auto lg:w-1/2 sm:px-10 md:px-6 lg:px-12 md:max-w-sm">
                {this.props.image}
            </div>
            <div className="flex flex-col mx-auto justify-center xl:pl-2 xl:pr-0 lg:w-1/2 lg:px-12">
                <h4 className={'text-normal mb-4 font-bold'}>{this.props.title}</h4>
                {this.props.children}
            </div>
        </Row>
        );
    }
};

class ImageBlockP extends React.Component {

    render() {
        return (
            <p className={'pb-4 leading-normal text-blue-100'}>
                    {this.props.children}
            </p>
        );
    }
};

export const FeaturesShapes = () => (

    <>

        <div className="section-header">
            <h2>Create your own design</h2>
            <p>With 2stitch creating your own designs is as easy as cake.</p>
        </div>


        <ImageBlock image={<YourOwnDesignImage/>} title={'Draw your own design'}>
            <ImageBlockP>
                Use the powerful tools to build your own designs with lines and filled shapes.
                Apply different fill and outline stitches to your shapes.
            </ImageBlockP>
            <ImageBlockP>
                You can even store the design elements in the shape library for future use.
            </ImageBlockP>
        </ImageBlock>

        <ImageBlock image={<SymbolLibraryImage/>} title={'Use design elements from the built-in shape library'}>
            <ImageBlockP>2stitch comes with a design element library with many shapes that
                you can use to enrich and compose your designs.</ImageBlockP>
            <ImageBlockP>You can modify the shapes in all possible ways. Apply
                different fill and outline stitches to it.</ImageBlockP>
        </ImageBlock>

        <ImageBlock image={<FontImage/>} title={'Add text and monograms'}>
            <ImageBlockP>
                Use the fonts from your computer to create texts and monograms.
                You can use fonts with fills and outline stitches.
            </ImageBlockP>
            <ImageBlockP>
                Letters can be modified, bent, distored or typed along lines and
                other shapes.
            </ImageBlockP>
        </ImageBlock>
    </>
);

/*

    <div className="container features text-center pt-4 pb-4 mt-4 mb-4">

        <h2>Shapes</h2>
        <p>
            Shapes are the building blocks of your designs.
            You can set outline and fill stitches.
        </p>

        <Row>

            <Feature title="Line / Polygon" icon={<PolyLineIcon/>}>
                Use this tool to create a straight line / polygon.
            </Feature>

            <Feature title="Curve" icon={<CurveLineIcon/>}>
                A curve is similar to a polygon but with rounded edges.
            </Feature>

            <Feature title="Filled Polygon" icon={<PolyFillIcon/>}>
                A shape with straight lines that consists of a fill and an outline.
            </Feature>

            <Feature title="Filled Curve" icon={<CurveFillIcon/>}>
                A curved shape with fill and outline.
            </Feature>

            <Feature title="Text" icon={<TextIcon/>}>
                The text tool converts any font on your computer
                into a shape.
            </Feature>

            <Feature title="Shape Library" icon={<ShapeLibraryIcon/>}>
                2stitch comes with many pre-defined shapes that you can use
                in your designs.
            </Feature>

        </Row>

    </div>

 */

export default FeaturesShapes
