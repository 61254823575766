import React from 'react'

import SimpleRunIcon from '../img/SVG/simple-run.svg';
import SatinRunIcon from '../img/SVG/satin-run.svg';
import MotiveRunIcon from '../img/SVG/motive-run.svg';

import Feature from '../components/feature';


export const FeaturesOutlines = () => (
    <div className="text-center">
        <div className="row">
            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Running Stitch"} icon={<SimpleRunIcon/>}>
                    A simple stitch that runs along the shape outline.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Satin Run"} icon={<SatinRunIcon/>}>
                    The satin stitch that runs along the outline of your shape.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Motive Run"} icon={<MotiveRunIcon/>}>
                    A motive that runs along the outline of the shape.
                    Use the included designs or create your own.
                </Feature>
            </div>
        </div>
    </div>
);

export default FeaturesOutlines
